<template>
    <div class="layout-container">
        <div class="top">
            <TopMenu :top="top" />
        </div>
        <div class="main">
            <router-view />
        </div>
        <div class="bottom">
            <BottomMenu />
        </div>
        <div class="contact-box">
            <Contact />
        </div>
    </div>
</template>

<script>
    import TopMenu from "@/components/TopMenu"
    import BottomMenu from "@/components/BottomMenu";
    import Contact from "@/components/Contact";
    export default {
        components:{TopMenu,BottomMenu,Contact},
        data() {
            return {
                top:0,
            }
        },
        created(){
            this.$store.dispatch('settings/getConfig')
        },
        mounted(){
            window.addEventListener('scroll',this.handleScroll)
        },
        beforeDestroy() {
            window.removeEventListener('scroll',this.handleScroll)
        },
        computed:{
            showContact(){
                return this.$route.path !== '/user/index'
            }
        },
        methods:{
            handleScroll(e) {
                this.$nextTick(()=>{
                    this.top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
                })
            },
        }
    }
</script>

<style scoped lang="less">
    .layout-container {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }
    .contact-box {
        position: fixed;
        width: 60px;
        background: #fff;
        box-shadow: 0px -1px 9px 0px rgba(167,167,167,0.15);
        bottom: 70px;
        right: 0;
        transform: translateY(-50%);
        z-index: 11;
    }
</style>