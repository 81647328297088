import { getConfig } from "@/api/index"
const state = {
    selectMenuIndex:0, //选品中心菜单选中索引,
    configInfo:null
}
const mutations = {
    CHANGE_SEMENUINDEX(state,index){
        state.selectMenuIndex = index
    },
    SET_CONFIG: (state, payload) => {
        state.configInfo = payload
    }
}
const actions = {
    async getConfig(ctx) {
        if (ctx.state.configInfo) {
          return
        }
        const { data } = await getConfig()
        console.log(data, 'data')
        ctx.commit('SET_CONFIG', data)
      }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}