<template>
    <div class="contact-container" v-if="configInfo">
        <div class="contact-item active">
            <img class="icon-img" src="../../assets/phone.png" alt="">
            <!-- 弹出层 -->
            <div class="pop">
                <div class="pop-title">{{ configInfo.service_phone || '暂无' }}</div>
            </div>
        </div>
        <div class="contact-item">
            <img class="icon-img" src="../../assets/email.png" alt="">
            <div class="pop">
                <div class="pop-title">{{ configInfo.service_email || '暂无' }}</div>
            </div>
        </div>
        <div class="contact-item">
            <img class="icon-img" src="../../assets/code.png" alt="">
            <div class="pop img">
                <img :src="configInfo.service_qrcode" alt="微信" @click="toOpenSite">
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
    export default {
        data() {
            return {
            }
        },
        computed:{
            ...mapState("settings",['configInfo'])
        },
        methods:{
            toOpenSite(){
                window.open('https://work.weixin.qq.com/kfid/kfc69b805c8a22bca8f','_blank')
            }
        }
    }
</script>

<style scoped lang="less">
@import "~@/styles/var.less";
    .contact-container {
        width: 100%;
        height: 100%;
    }
    .contact-item {
        padding:18px 0;
        height: 66px;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        cursor: pointer;
        &:hover {
            .pop {
                transform: scaleY(1);
            }
        }
        &:not(:last-child){
            border-bottom: 1px solid #D8D8D8;
        }
    }
    .contact-item .icon-img{
        width: 28px;
        height: 28px;
        filter: grayscale(100%);
    }
    .contact-item.active {
        color: @primary;
    }
    .pop {
        position: absolute;
        right: 100px;
        top: 0;
        padding: 21px 36px;
        min-width: 158px;
        background: #fff;
        transform: scaleY(0);
        transform-origin: right top;
        transition: 0.5s;
        box-shadow: 0px -1px 10px 0px rgba(126,126,126,0.15);
        box-sizing: border-box;
    }
    .pop.img {
        padding: 20px;
    }
    .pop-title {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: @words;
    }
    .pop::after {
        content: '';
        position: absolute;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        width: 15px;
        height: 15px;
        background: #fff;
        right: -15px;
        top: 50%;
    }
    .pop.img::after{
        top: 33px;
    }
    .pop img {
        width: 119px;
        height: 119px;
    }
</style>